import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ShowroomWidgetIcon from "../../../../static/ShowroomWidgetIcon.png"
import ShowroomCalendarIcon from "../../../../static/ShowroomCalendarIcon.png"

const ShowroomWidgetWrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  right: 30px;
  top: 230px;
  transform: translate(0%, -50%);
  z-index: 4;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;

  @media (max-width: 1600px) {
    right: 20px;
  }

  @media (max-width: 1200px) {
    right: 0px;
  }

  @media (max-width: 600px) {
    top: ${({ page }) => (page === "sommier-zones" ? "170px" : "210px")};
  }
`

const WidgetBox = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #262626;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  position: relative;
  border: 1px solid #262626;
  padding: 5px 10px;

  .hoverImage {
    display: none;
  }

  :hover {
    background: #fff;
    color: #262626;

    .normalImage {
      display: none;
    }

    .hoverImage {
      display: block;
    }
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const WidgetImage = styled.img`
  width: 100%;
  max-width: 18px;
  margin: 0px 5px 0px 0px;
`

const WidgetHoverImage = styled.img`
  width: 100%;
  max-width: 18px;
  margin: 0px 5px 0px 0px;
`

const ShowroomWidget = ({ intl, page }) => {
  return (
    <ShowroomWidgetWrapper className="showroom-widget" page={page}>
      <WidgetBox to="/showroom/">
        <WidgetImage className="normalImage" src={ShowroomCalendarIcon} />
        <WidgetHoverImage className="hoverImage" src={ShowroomWidgetIcon} />
        Prendre rendez-vous
      </WidgetBox>
    </ShowroomWidgetWrapper>
  )
}

export default injectIntl(ShowroomWidget)
